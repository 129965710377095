const data = [
  {
    name: "Shri Anil Sachdev",
    desc: "Chairman",
  },
  {
    name: "Ms. Shibani Khorana",
    desc: "Manager",
  },
  {
    name: "Mr. Shailendra Kumar",
    desc: "Member",
  },
  {
    name: "Mr. Rakesh Jinsi",
    desc: "Member",
  },
  {
    name: "Smt. Sharda Chawla, Member",
    desc: "Member",
  },
  {
    name: "Dr (Mrs) Vimla Veeraraghavan",
    desc: "Member",
  },
  {
    name: "Ms. Kumkum Bhatia",
    desc: "Member",
  },
  {
    name: "Mrs. Lata Vaidyanathan",
    desc: "Member",
  },
  {
    name: "Shri Jujhar Singh",
    desc: "Member",
  },
  {
    name: "Smt Kalpana Vaswani",
    desc: "Member",
  },
  {
    name: "Dr. Rajesh Hassija",
    desc: "Member",
  },
  {
    name: "Mr. Ashutosh Batta",
    desc: "Member",
  },
  {
    name: "Mrs. Vandana, Principal",
    desc: "DE Nominee",
  },
  {
    name: "Ms. Alka Anurala",
    desc: "DE Nominee",
  },
  {
    name: "Ms. Anjali Gupta",
    desc: "Advisory Board Nominee",
  },
  {
    name: "Ms. Alpana Kumar",
    desc: "Advisory Board Nominee",
  },
  {
    name: "Ms. Archana Soni",
    desc: "Principal / Member Secretary",
  },
  {
    name: "Ms. Jyoti K. Nambiar",
    desc: "Parent Rep.",
  },
  {
    name: "Mrs. Parul Verma",
    desc: "Tr. Rep.",
  },
  {
    name: "Mrs. Gayatri Banerjee",
    desc: "Tr. Rep.",
  },
];

export default data;
